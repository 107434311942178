import axios from 'axios'
import VueAxios from 'vue-axios'

const state = {
  res: {
    banner: [],
    category: [],
    field: [],
    info: {}
  },
  init: false
}

const getters = {
  getRes: (state, getters) => {
    return state.res
  }
}

const mutations = {
  getRes(state, res) {
    state.res = res
  },
  initDone(state, value) {
    state.init = value
  }
}

const actions = {
  getRes({commit, state}) {
    if (!this.init) {
      axios({ url: "/api/res" }).then(res => {
        if (res.data.success) {
          commit('getRes', res.data.data)
          commit('initDone', true)
          document.title = res.data.data.info.title
        }
      }, err => {
        console.log(err);
      })
    }
  },
  init({commit, state}) {
    commit('initDone', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
