<template>
	<top></top>
	<router-view/>
	<bottom></bottom>
</template>

<script>
	import top from './views/top.vue'
	import bottom from './views/bottom.vue'
	export default {
		name: 'APP',
		components: {top,bottom},
		mounted() {
			
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 100%;
	}
	@media only screen and (min-width:900px){
		#app {
			padding-top: 70px;
		}
	}
	@media (min-width: 10px) and (max-width: 900px){
		#app {
			padding-top: 50px;
		}
	}
</style>
