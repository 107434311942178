<template>
	<div class="home">
		<div class="banner">
			<swiper :modules="modules" FreeMode :autoplay="{ autoplay: true}" :pagination="{clickable: true}"
				@swiper="onSwiper" @slideChange="onSlideChange">
				<swiper-slide v-for="(item, index) in res.banner"><img :src="item.imgUrl" /></swiper-slide>
				<div class="hs-num"><span>1</span><div>0{{res.banner.length}}</div></div>
			</swiper>
			<div class="bannerC">
				<img src="../assets/img/bannerC.png">
			</div>
		</div>
		<div class="hyy">
			<h3>应用领域</h3>
			<h4>满足各种电能转换系统的应用</h4>
		</div>
		<div class="ha">
			<div v-for="(item, index) in res.field" @click="toRouter(item.id)">
				<img :src="item.img">
				<h3>{{item.cn}}</h3>
				<p>{{item.en}}</p>
				<i></i>
			</div>
		</div>
		<div class="hyy">
			<h3>产品信息</h3>
			<h4>专注半导体器件技术创新</h4>
		</div>
		<div class="hb">
			<div>
				<div class="hb-tab">
					<span class="active" @click="toRouter(7)">SGTMOS</span>
				</div>
				<div class="hb-info">
					<p>Triangle Chip MOS在电动自行车、电动摩托车、电动叉车、电动清扫车、电动物流车、老人代步车等领域经过市场充分其优秀的抗冲击能力针对电机控制系统专门设计，在堵转等恶劣工况过流测试条件下仍能稳定工作。</p>
					<p>MOSFET, 采用独特专利器件结构和制造工艺，GreenMOS产品具有比常规MOSFET更快的开关速度及更柔和的开关曲线，在获得极低的动态损耗的同时大限度抑制了开关震荡。</p>
				</div>
			</div>
		</div>
		<div class="hc">更多信息，请与我们联系</div>
	</div>
	<div class="mhome">
		<div class="banner">
			<swiper :modules="modules" FreeMode :autoplay="{ autoplay: true}" :pagination="{clickable: true}"
				@swiper="onSwiper" @slideChange="onSlideChange">
				<swiper-slide v-for="(item, index) in res.banner"><img :src="item.imgUrl" /></swiper-slide>
				<div class="hs-num"><span>1</span><div>0{{res.banner.length}}</div></div>
			</swiper>
			<div class="bannerC">
				<img src="../assets/img/mbc.png">
			</div>
		</div>
		<div class="hyy">
			<h3>应用领域</h3>
			<h4>满足各种电能转换系统的应用</h4>
		</div>
		<div class="ha">
			<div v-for="(item, index) in res.field" @click="toRouter(item.id)">
				<h3>{{item.cn}}</h3>
				<p>{{item.en}}</p>
			</div>
		</div>
		<div class="hyy">
			<h3>产品信息</h3>
			<h4>专注半导体器件技术创新</h4>
		</div>
		<div class="hb">
			<div>
				<div class="hb-tab">
					<span class="active" @click="toRouter(7)">SGTMOS</span>
				</div>
				<div class="hb-info">
					<p>Triangle Chip MOS在电动自行车、电动摩托车、电动叉车、电动清扫车、电动物流车、老人代步车等领域经过市场充分其优秀的抗冲击能力针对电机控制系统专门设计，在堵转等恶劣工况过流测试条件下仍能稳定工作。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	import {
		Pagination,
		Autoplay
	} from 'swiper';
	import 'swiper/css';
	import 'swiper/css/pagination';
	import { mapState } from 'vuex';

	export default {
		name: 'Home',
		components: {
			Swiper,
			SwiperSlide,
		},
		computed: {
			...mapState({
				res: state => state.res.res
			})
		},
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {
			$($('.tb-router').children('span')).removeClass('active');
      $($('.tb-router').children('span')[0]).addClass('active');
		},
		methods: {
			toRouter(categoryId) {
				if (categoryId == 0) {
					this.$router.push({path: "/"});
				} else {
					this.$router.push({path: '/details/' + categoryId})
				}
			},
		},
		setup() {
			const onSwiper = (swiper) => {};
			const onSlideChange = (swiper) => {
				$('.hs-num span').html(swiper.activeIndex + 1)
			};
			return {
				onSwiper,
				onSlideChange,
				modules: [Pagination, Autoplay],
			};
		}
	}
</script>

<style scoped>
	.swiper {
		min-width: 1260px;
		width: 100%;
		position: relative;
	}

	.swiper img {
		width: 100%;
	}

	.hs-num {
		position: absolute;
		top: 200px;
		right: calc((100% - 1260px) / 2);
		display: flex;
		color: #fff;
		z-index: 10;
	}
	
	.mhome .hs-num {
		top: auto;
		left: 50%;
		right: auto;
		bottom: 50px;
		transform: translateX(-50%);
	}

	.hs-num span {
		height: 36px;
		line-height: 36px;
		font-size: 36px;
		margin-right: 16px;
	}
	.mhome .hs-num span {
		height: 24px;
		line-height: 24px;
		font-size: 24px;
		margin-right: 10px;
	}

	.hs-num div {
		font-size: 18px;
	}
	
	.mhome .hs-num div {
		font-size: 12px;
	}
	
	.banner {
		position: relative;
	}

	.bannerC {
		min-width: 1260px;
		width: 100%;
		position: absolute;
		bottom: -1px;
		z-index: 9;
	}
	.bannerC img {
		width: 100%;
	}
	.hyy {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 11;
		margin-top: 92px;
		min-width: 1260px;
	}
	.mhome .hyy {
		min-width: auto;
		margin-top: 46px;
	}
	.banner + .hyy {
		margin-top: -50px;
	}
	.mhome .banner + .hyy {
		margin-top: 0;
	}
	.hyy h3 {
		font-size: 40px;
		font-weight: normal;
		height: 40px;
		line-height: 40px;
	}
	.mhome .hyy h3 {
		font-size: 22px;
		height: 22px;
		line-height: 22px;
	}
	.hyy h4 {
		font-size: 20px;
		color: #273679;
		font-weight: normal;
		height: 20px;
		line-height: 20px;
		margin: 28px 0 0;
	}
	.mhome .hyy h4 {
		font-size: 15px;
		color: #273679;
		height: 15px;
		line-height: 15px;
		margin: 15px 0 0;
	}
	.home .ha {
		width: 1260px;
		margin: 24px auto 0;
		height: 920px;
		display: flex;
		flex-wrap: wrap;
		padding-top: 80px;
		background: url('../assets/img/field.png') no-repeat center 4px;
		background-size: 453px 138px;
		position: relative;
	}
	.home .ha div {
		width: 630px;
		height: 420px;
		overflow: hidden;
		cursor: pointer;
		position: relative;
		color: #fff;
	}
	.home .ha img {
		width: 630px;
		height: 420px;
		transition: all 0.8s linear 0.1s;
		-moz-transition: all 0.8s linear 0.1s;
		-webkit-transition: all 0.8s linear 0.1s;
		-o-transition: width all 0.8s linear 0.1s;
	}
	.home .ha div:hover img {
		transform: scale(1.1,1.1);
		-ms-transform: scale(1.1,1.1);
		-webkit-transform: scale(1.1,1.1);
		-o-transform: scale(1.1,1.1);
		-moz-transform: scale(1.1,1.1);
	}
	.home .ha h3 {
		font-size: 36px;
		line-height: 56px;
		height: 56px;
		position: absolute;
		transition: all 0.8s linear 0.1s;
		-moz-transition: all 0.8s linear 0.1s;
		-webkit-transition: all 0.8s linear 0.1s;
		-o-transition: width all 0.8s linear 0.1s;
	}
	.home .ha div:nth-child(1) h3 {
		top: 50px;
		left: 52px;
	}
	.home .ha div:nth-child(2) h3 {
		top: 50px;
		right: 52px;
	}
	.home .ha div:nth-child(3) h3 {
		top: 278px;
		left: 52px;
	}
	.home .ha div:nth-child(1) i {
		width: 78px;
		height: 78px;
		position: absolute;
		right: 66px;
		bottom: 56px;
		border: 1px solid #fff;
		background: url('../assets/img/hadd.png') no-repeat center;
		background-size: 36px 36px;
		display: none;
	}
	.home .ha div:nth-child(2) i {
		width: 78px;
		height: 78px;
		position: absolute;
		left: 66px;
		bottom: 56px;
		border: 1px solid #fff;
		background: url('../assets/img/hadd.png') no-repeat center;
		background-size: 36px 36px;
		display: none;
	}
	.home .ha div:nth-child(3) i {
		width: 78px;
		height: 78px;
		position: absolute;
		right: 66px;
		bottom: 56px;
		border: 1px solid #fff;
		background: url('../assets/img/hadd.png') no-repeat center;
		background-size: 36px 36px;
		display: none;
	}
	.home .ha div:nth-child(4) i {
		width: 78px;
		height: 78px;
		position: absolute;
		left: 66px;
		bottom: 56px;
		border: 1px solid #fff;
		background: url('../assets/img/hadd.png') no-repeat center;
		background-size: 36px 36px;
		display: none;
	}
	.home .ha div:nth-child(1):hover i {
		display: block;
	}
	.home .ha div:nth-child(2):hover i {
		display: block;
	}
	.home .ha div:nth-child(3):hover i {
		display: block;
	}
	.home .ha div:nth-child(4):hover i {
		display: block;
	}
	.home .ha div:nth-child(4) h3 {
		top: 278px;
		right: 52px;
	}
	.home .ha p {
		font-size: 30px;
		line-height: 42px;
		height: 42px;
		position: absolute;
		font-weight: 100;
		transition: all 0.8s linear 0.1s;
		-moz-transition: all 0.8s linear 0.1s;
		-webkit-transition: all 0.8s linear 0.1s;
		-o-transition: width all 0.8s linear 0.1s;
	}
	.home .ha div:nth-child(1) p {
		top: 106px;
		left: 52px;
	}
	.home .ha div:nth-child(2) p {
		top: 106px;
		right: 52px;
	}
	.home .ha div:nth-child(3) p {
		top: 332px;
		left: 52px;
	}
	.home .ha div:nth-child(4) p {
		top: 332px;
		right: 52px;
	}
	.home .ha:after {
		content: '';
		width: 35px;
		height: 35px;
		position: absolute;
		left: 613px;
		bottom: 403px;
		background-color: #fff;
		transform: rotate(45deg)
	}
	.mhome .ha {
		width: 100%;
		height: 287px;
		background: url('../assets/img/mha.png') no-repeat center 0;
		background-size: 227px 68px;
		margin-top: 18px;
		display: flex;
		flex-wrap: wrap;
		padding-top: 37px;
		position: relative;
	}
	.mhome .ha div {
		width: 50%;
		height: 125px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #fff;
	}
	.mhome .ha div:nth-child(1) {
		background: url('../assets/img/mha1.jpg') no-repeat center;
		background-size: 100% auto;
	}
	.mhome .ha div:nth-child(2) {
		background: url('../assets/img/mha2.jpg') no-repeat center;
		background-size: 100% auto;
	}
	.mhome .ha div:nth-child(3) {
		background: url('../assets/img/mha3.jpg') no-repeat center;
		background-size: 100% auto;
	}
	.mhome .ha div:nth-child(4) {
		background: url('../assets/img/mha4.jpg') no-repeat center;
		background-size: 100% auto;
	}
	.mhome .ha h3 {
		font-size: 18px;
	}
	.mhome .ha p {
		font-size: 12px;
		font-weight: 100;
	}
	.mhome .ha:after {
		content: '';
		width: 22px;
		height: 22px;
		position: absolute;
		left: calc(50% - 11px);
		bottom: 114px;
		background-color: #fff;
		transform: rotate(45deg)
	}
	.hb {
		min-width: 1260px;
		margin-top: 34px;
		width: 100%;
		height: 912px;
		background: url('../assets/img/hb.jpg') no-repeat center;
		background-size: 1918px 912px;
		overflow: hidden;
	}
	.mhome .hb {
		min-width: auto;
		margin-top: 20px;
		width: 100%;
		height: 633px;
		background: url('../assets/img/mhb.jpg') no-repeat center;
		background-size: 100% 633px;
		overflow: hidden;
	}
	.hc {
		width: 100%;
		height: 295px;
		background: url('../assets/img/hc.jpg') no-repeat center;
		background-size: 1920 295px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 40px;
	}
	.hb > div {
		width: 1260px;
		margin: 194px auto 0;
		padding: 60px 50px;
	}
	.mhome .hb > div {
		width: calc(100% - 36px);
		margin: 70px auto 0;
		padding: 21px 25px;
	}
	.hb-tab {
		display: flex;
	}
	.hb-tab span {
		height: 40px;
		padding: 0 20px;
		margin-right: 20px;
		font-size: 18px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.mhome .hb-tab span {
		height: 28px;
		padding: 0 10px;
		margin-right: 7px;
		font-size: 14px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.hb-tab span.active {
		background-color: #273679;
		color: #fff;
	}
	.hb-info {
		padding: 90px 0 0;
		font-size: 16px;
		width: 460px;
		line-height: 30px;
	}
	.mhome .hb-info {
		width: 100%;
		font-size: 11px;
		padding: 28px 0 0;
		color: #444;
		line-height: 20px;
	}
	.hb-info p:first-child {
		margin-bottom: 30px;
	}
	.mhome .bannerC,.mhome .swiper {
		min-width: 0;
	}
	
	@media (min-width: 10px) and (max-width: 900px){
		.home {
			display: none;
		}
		.mhome {
			display: block;
		}
	}
	@media (min-width:900px){
		.home {
			display: block;
		}
		.mhome {
			display: none;
		}
	}
</style>
