import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/details/:categoryId',
    name: 'details',
    component: () => import('../views/Details.vue')
  }
  // {
  //   path: '/apps',
  //   name: 'apps',
  //   component: () => import('../views/Apps.vue')
  // },
  // {
  //   path: '/info',
  //   name: 'info',
  //   component: () => import('../views/Info.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/About.vue')
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
