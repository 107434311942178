<template>
	<div class="bottom">
		<div class="bl">
			<h3>联系我们</h3>
			<h2>{{res.info.phone}}</h2>
			<p><span>地址：</span>{{res.info.address}}</p>
			<p><span>邮箱：</span>{{res.info.mail}}</p>
		</div>
		<div class="btr">
			<h4>应用领用</h4>
			<template v-for="(item) in res.category">
				<p v-if="(item.parentId == 1)" @click="toRouter(item.id)">{{item.name}}</p>
			</template>
		</div>
		<div class="btr">
			<h4>产品信息</h4>
			<template v-for="(item) in res.category">
				<p v-if="(item.parentId == 2)" @click="toRouter(item.id)">{{item.name}}</p>
			</template>
		</div>
		<div class="btr">
			<h4>关于特安高</h4>
			<template v-for="(item) in res.category">
				<p v-if="(item.parentId == 3)" @click="toRouter(item.id)">{{item.name}}</p>
			</template>
		</div>
	</div>
	<div class="bc">{{res.info.copyright}}  <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023004381号</a></div>
	<div class="mbc">
		<p>{{res.info.copyright}}<br/><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023004381号</a></p>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState({
				res: state => state.res.res
			})
		},
		methods: {
			toRouter(categoryId) {
				if (categoryId == 0) {
					this.$router.push({path: "/"});
				} else {
					$('.mt-mask').hide();
					window.scrollTo(0,0);
					this.$router.push({path: '/details/' + categoryId})
				}
			},
		}
	}
</script>

<style scoped>
	.bottom {
		width: 1260px;
		margin: 0 auto;
		display: flex;
		padding-top: 92px;
		justify-content: space-between;
	}
	
	.bl {
		width: 426px;
		border-right: 1px solid #f2f2f2;
	}
	.bl h3 {
		font-size: 16px;
		font-weight: bold;
	}
	.bl h2 {
		font-size: 26px;
		margin: 24px 0 26px;
		line-height: 28px;
		height: 28px;
	}
	.bl h2:before {
		content: '';
		display: inline-block;
		width: 28px;
		height: 28px;
		background: url('../assets/img/bl2.jpg') no-repeat;
		background-size: 28px 28px;
		margin-right: 12px;
		vertical-align: text-top;
	}
	.bl p {
		font-size: 14px;
		color: #444;
	}
	.bl p:last-child {
		margin-top: 18px;
	}
	.btr p {
		font-size: 14px;
		line-height: 14px;
		color: #444;
		margin-bottom: 18px;
		cursor: pointer;
	}
	.btr p:last-child {
		margin-bottom: 0;
	}
	.btr h4 {
		font-size: 16px;
		margin-bottom: 26px;
		font-weight: bold;
	}
	.bc {
		font-size: 14px;
		color: #999;
		width: 1260px;
		margin: 72px auto 42px;
		text-align: center;
	}
	.mbc {
		display: none;
	}
	@media (min-width: 10px) and (max-width: 900px){
		.btr {
			display: none;
		}
		.bottom {
			width: 100%;
			padding: 40px 0 12px;
		}
		.bl {
			display: flex;
			width: 100%;
			flex-direction: column;
			border-right: 0;
		}
		.bl h3 {
			display: none;
		}
		.bl h2 {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			margin: 0 0 12px;
			font-size: 21px;
		}
		.bl h2:before {
		    width: 23px;
		    height: 23px;
			background-size: 23px 23px;
		    margin-right: 10px;
		}
		.bl p {
			display: flex;
			justify-content: center;
			font-size: 11px;
		}
		.bl p:last-child {
			margin-top: 8px;
		}
		.bl p span {
			display: none;
		}
		.bc {
			display: none;
		}
		.mbc {
			display: flex;
			flex-direction: column;
			font-size: 10px;
			line-height: 18px;
			color: #999;
			align-items: center;
			padding-bottom: 28px;
		}
	}
</style>