<template>
	<div class="top">
		<div>
			<img v-if="res.info.logoUrl" :src="res.info.logoUrl">
			<div class="top-bar">
				<div class="tb-router">
					<!-- <span v-for="item in routerBars" :class="{active: item.url === routerBar}" @click="toRouter(item.url)">{{ item.text }}</span> -->
					<span @click="toRouter(0)">首页</span>
					<template v-for="(item) in res.category">
						<span v-if="(item.level == 1)" @click="toRouter(item.id)">{{item.name}}</span>
					</template>
					<div class="tr-box">
						<div>
							<div class="trb-list">
								<template v-for="(item) in res.category">
									<p v-if="(item.parentId == 1)" @click="toRouter(item.id)">{{item.name}}</p>
								</template>
							</div>
							<div class="trb-list">
								<template v-for="(item) in res.category">
									<p v-if="(item.parentId == 2)" @click="toRouter(item.id)">{{item.name}}</p>
								</template>
							</div>
							<div class="trb-list">
								<template v-for="(item) in res.category">
									<p v-if="(item.parentId == 3)" @click="toRouter(item.id)">{{item.name}}</p>
								</template>
							</div>
							<div class="trb-img"></div>
						</div>
					</div>
				</div>
				<div class="tb-lang">
					<span class="acitve">中文</span>
					<span>EN</span>
					<i></i>
				</div>
				<div class="tb-search"></div>
				<div class="tbs-box">
					<span>产品</span>
					<input type="text">
					<button></button>
				</div>
			</div>
		</div>
	</div>
	<div class="mtop">
		<img src="../assets/img/logo.jpg">
		<i id="showMask"></i>
	</div>
	<div class="mt-mask">
		<div class="mtop">
			<img src="../assets/img/logo.jpg">
			<i id="closeMask"></i>
		</div>
		<div class="mt-box">
			<ul>
				<li>
					<span @click="toRouter(0)">首页</span>
				</li>
				<template v-for="(item) in res.category">
					<li>
						<span v-if="(item.level == 1)" @click="categoryClick($event)">{{item.name}}</span>
						<div>
							<template v-for="(child) in res.category">
								<p v-if="(child.parentId == item.id)" @click="toRouter(child.id)">{{child.name}}</p>
							</template>
						</div>
					</li>
				</template>
				<!-- <p class="active">电机驱动</p> -->
				<li class="mtb-find">
					<i></i>
					<input type="text" placeholder="输入产品关键字">
				</li>
			</ul>
			<div class="tb-lang">
				<span class="acitve">中文</span>
				<span>EN</span>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	
	export default {
		name: 'top',
		data() {
			return {
			}
		},
		computed: {
			...mapState({
				res: state => state.res.res
			})
		},
		created() {
			this.$store.dispatch('res/getRes')
		},
		mounted() {
			let _this = this;
			$(".tb-search").click(function(){
				$(".tbs-box").slideToggle();
			});
			$('#showMask').on('click',function(){
				$('.mt-mask').show()
			})
			$('#closeMask').on('click',function(){
				$('.mt-mask').hide()
			})
		},
		methods: {
			toRouter(categoryId) {
				if (categoryId == 0) {
					this.$router.push({path: "/"});
				} else {
					$('.mt-mask').hide();
					window.scrollTo(0,0);
					this.$router.push({path: '/details/' + categoryId})
				}
			},
			categoryClick(e) {
				if($(e.target).text() === '首页') {
					_this.$router.push({name: 'home'});
					$('.mt-mask').hide();
					window.scrollTo(0,0);
				}
				if($(e.target).parent().hasClass('on')){
					$(e.target).parent().removeClass('on');
					$(e.target).next().stop().slideUp();
				}else{
					$(e.target).parent().addClass('on').siblings().removeClass('on');
					$('.mt-box li div').hide();
					$(e.target).next().stop().slideDown();
				}
			}
		},
		watch: {
			$route(to, from) {
				$('.mt-mask').hide();
				this.routerBar = to.name;
			}
		},
	}
</script>

<style scoped>
	.top {
		width: 100%;
		height: 70px;
		background-color: #fff;
		position: fixed;
		top: 0;
		z-index: 12;
	}
	.top > div {
		width: 1260px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.top img {
		width: 150px;
		height: 50px;
		display: flex;
	}
	.top-bar {
		font-size: 16px;
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;
	}
	.tb-router {
		display: flex;
		height: 100%;
	}
	.tb-router > span {
		height: 100%;
		padding: 0 60px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.tb-router > span.active {
		font-weight: bold;
		position: relative;
	}
	.tb-router > span.active:before {
		content: '';
		position: absolute;
		bottom: 8px;
		background: url('../assets/img/hActive.png') no-repeat;
		width: 22px;
		height: 16px;
		left: 50%;
		margin-left: -11px;
	}
	.tb-router > span:last-child {
		margin-right: 0;
	}
	.tb-lang {
		color: #999;
		margin-left: 170px;
		height: 16px;
		display: flex;
	}
	.tb-lang span {
		font-size: 14px;
		line-height: 16px;
		height: 16px;
		cursor: pointer;
	}
	.tb-lang span.acitve {
		color: #000;
	}
	.tb-lang span:first-child {
		margin-right: 16px;
	}
	.tb-lang i {
		content: '';
		display: inline-block;
		margin-left: 20px;
		width: 1px;
		height: 16px;
		background-color: #ccc;
	}
	.tb-search {
		width: 18px;
		height: 100%;
		background: url('../assets/img/search.png') no-repeat center;
		background-size: 18px 17px;
		margin-left: 20px;
		cursor: pointer;
	}
	.tbs-box {
		position: absolute;
		right: -18px;
		top: 100%;
		width: 316px;
		height: 44px;
		border: solid 1px #263472;
		padding-right: 54px;
		background: #fff;
		z-index: 10;
		display: none;
	}
	.tbs-box span {
		float: left;
		width: 90px;
		height: 42px;
		background-color: #f5f5f5;
		text-align: center;
		line-height: 42px;
		color: #4c4c4c;
	}
	.tbs-box input {
		float: left;
		width: 166px;
		height: 42px;
		background: none;
		border: none;
		line-height: 22px;
		padding: 10px;
		color: #333;
		font-size: 14px;
	}
	.tbs-box button {
		width: 52px;
		height: 42px;
		border: none;
		background: url('../assets/img/search1.png') center no-repeat #263472;
		background-size: 24px 24px;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
	}
	.tbs-box button:before {
		content: '';
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: 7px solid #263472;
		position: absolute;
		left: 50%;
		top: -7px;
		margin-left: -8px;
	}
	.tr-box {
		position: fixed;
		top: 70px;
		left: 0;
		height: 210px;
		width: 100%;
		background-color: #fff;
		z-index: 9;
		display: none;
	}
	@keyframes fadeInUp1{
		0%{opacity:0;top: 100px}
		100%{opacity:1;top: 70px}
	}
	.tb-router:hover .tr-box{
		display: block;
		animation: fadeInUp1 0.5s 0s ease both;
	}
	.tr-box > div {
		width: 1260px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		padding-top: 20px;
	}
	.trb-list {
		width: 185px;
		padding-left: 20px;
		border-left: 1px solid #e3e3e3;
		height: 143px;
	}
	.trb-list p {
		padding-left: 10px;
		height: 32px;
		line-height: 32px;
		width: 115px;
		cursor: pointer;
		margin-bottom: 5px;
	}
	.trb-list p:hover {
		background-color: #273679;
		color: #fff;
	}
	.trb-list p:last-child {
		margin-bottom: 0;
	}
	.trb-img {
		margin-left: 52px;
		width: 228px;
		height: 143px;
		background: url('../assets/img/topDown.jpg') no-repeat;
		background-size: 298px 199px;
	}
	
	
	.mtop {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		position: fixed;
		top: 0;
		z-index: 12;
	}
	.mtop img {
		width: 113px;
		height: 38px;
	}
	.mtop i {
		width: 50px;
		height: 50px;
		background: url('../assets/img/mtd.jpg') no-repeat center;
		background-size: 21px 18px;
		cursor: pointer;
	}
	.mt-mask {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 12;
		background-color: rgba(0, 0, 0, .5);
		display: none;
	}
	.mt-mask .mtop i {
		background: url('../assets/img/mtx.jpg') no-repeat center;
		background-size: 18px 18px;
	}
	.mt-box {
		position: absolute;
		top: 50px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		padding: 20px 44px 40px;
		background-color: #fff;
	}
	.mt-box li {
		border-bottom: 1px solid #f6f6f6;
	}
	.mt-box li span {
		display: flex;
		height: 60px;
		align-items: center;
		justify-content: space-between;
		font-size: 17px;
	}
	.mt-box li span:after {
		content: '';
		width: 7px;
		height: 12px;
		background: url('../assets/img/mtbx.jpg') no-repeat center;
		background-size: 7px 12px;
		-webkit-transition: all 0.3s ease-out 0s;
		-moz-transition: all 0.3s ease-out 0s;
		-o-transition: all 0.3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
	}
	.mt-box li:first-child span:after {
		display: none;
	}
	.mt-box li div {
		margin-top: -4px;
		padding-bottom: 10px;
		display: none;
	}
	.mt-box li.on span:after {
		transform: rotate(90deg);
		-webkit-transition: all 0.3s ease-out 0s;
		-moz-transition: all 0.3s ease-out 0s;
		-o-transition: all 0.3s ease-out 0s;
		transition: all 0.3s ease-out 0s;
	}
	.mt-box li p {
		height: 40px;
		display: flex;
		align-items: center;
		color: #273679;
		font-size: 15px;
	}
	.mt-box li p.active {
		font-weight: bold;
	}
	.mtb-find {
		display: flex;
	}
	.mtb-find i {
		width: 60px;
		height: 60px;
		background: url('../assets/img/mtb-find.jpg') no-repeat center;
		background-size: 17px 17px;
		cursor: pointer;
	}
	.mtb-find input {
		width: calc(100% - 60px);
	}
	.mt-mask .tb-lang {
		margin-left: 0;
		padding-top: 26px;
	}
	@media (min-width: 10px) and (max-width: 900px){
		.top {
			display: none;
		}
		.mtop {
			display: flex;
		}
	}
	@media (min-width:900px){
		.top {
			display: block;
		}
		.mtop {
			display: none;
		}
	}
</style>